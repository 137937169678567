import { React, useRef, useEffect } from "react";
import useState from 'react-usestateref';
import Meta from "./../components/Meta";
import Navbar from "../components/Navbar";
import { useAuth, requireAuth } from "./../util/auth";
import Geocode from "react-geocode";
import { updateUser } from "../util/db";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function IndexPage(props) {

  const isFirstRender = useRef(true);
  const auth = useAuth();
  const [origin, setOrigin] = useState(auth.user.settings.default_start_location || "");
  const originLatLong = useRef("");
  const [destination, setDestination] = useState("");
  const destinationLatLong = useRef("");
  const [viaDestination, setViaDestination] = useState([]);
  const [viaDestinationLatLong, setViaDestinationLatLong, viaDestinationLatLongRef] = useState([]);
  const [departAt, setDepartAt] = useState(new Date());
  const [holiday_rate, setHoliday_rate] = useState(false);
  const [seats, setSeats] = useState(false);
  const detailedPrice = useRef(auth.user.settings.detailedPrice || false);
  const [json, setJson] = useState({});
  const weekday = useRef(new Date().getDay());
  const [weekdayState, setWeekdayState] = useState(new Date().getDay());
  const hour = useRef(new Date().getMinutes() >= 45 ? (new Date().getHours() + 1) : new Date().getHours());
  const [hourState, setHourState] = useState(new Date().getMinutes() >= 45 ? (new Date().getHours() + 1) : new Date().getHours());
  const minute = useRef((Math.ceil(new Date().getMinutes()/15) * 15) % 60);  
  const [minuteState, setMinuteState] = useState((Math.ceil(new Date().getMinutes()/15) * 15) % 60);  
  const hoursArray = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23];
  const hoursLabelArray = ["00","01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23"];
  const minutesArray = [0,15,30,45];
  const minutesLabelArray = ["00","15","30","45"];

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    
  }, [origin, destination, departAt, holiday_rate, seats, originLatLong, destinationLatLong]); 

  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);
  Geocode.setLanguage("en");
  Geocode.setRegion("gb");
  Geocode.setLocationType("ROOFTOP");

  function handleWeekdayChange(newValue) {
    weekday.current = parseInt(newValue.target.value)
    setWeekdayState(parseInt(newValue.target.value))
    calculateDepartureTime()
  }
  function handleHourChange(newValue) {
    hour.current = parseInt(newValue.target.value)
    setHourState(parseInt(newValue.target.value))
    calculateDepartureTime()
  }
  function handleMinuteChange(newValue) {
    minute.current = parseInt(newValue.target.value)
    setMinuteState(parseInt(newValue.target.value))
    calculateDepartureTime()
  }
  function getNextDayOfWeek(date, dayOfWeek) {
    var resultDate = new Date(date.getTime());
    resultDate.setDate(date.getDate() + (7 + dayOfWeek - date.getDay()) % 7);
    return resultDate;
  }
  function calculateDepartureTime() {
    const newUnixDate = Date.parse(getNextDayOfWeek(new Date(), weekday.current).toDateString() + " " + hour.current.toString() + ":" + minute.current.toString())
    const newFullDate = new Date(newUnixDate)
    setDepartAt(newFullDate)
  }
  function handleNow() {
    weekday.current = new Date().getDay();
    setWeekdayState(new Date().getDay());
    hour.current = new Date().getMinutes() >= 45 ? (new Date().getHours() + 1) : new Date().getHours();
    setHourState(new Date().getMinutes() >= 45 ? (new Date().getHours() + 1) : new Date().getHours())
    minute.current = (Math.ceil(new Date().getMinutes()/15) * 15) % 60;
    setMinuteState((Math.ceil(new Date().getMinutes()/15) * 15) % 60);
  }

  let getOriginAddress = async () => {
    try {
      await Geocode.fromAddress(origin).then(
        (response) => {
          if (response.status !== "OK") {
            throw new Error('No Results found for Origin')
          }
          originLatLong.current = response.results[0].geometry.location.lat + "," + response.results[0].geometry.location.lng
          setOrigin(response.results[0].formatted_address)
          return response.results[0].geometry.location.lat + "," + response.results[0].geometry.location.lng
        },
        (error) => {
          throw new Error(error)
        }
      )
    }
    catch (error) {
      throw new Error(error)
    }
  }

  let getDestinationAddress = async () => {
    try {
      await Geocode.fromAddress(destination).then(
        (response) => {
          if (response.status !== "OK") {
            throw new Error('No Results found for Destination')
          }
          destinationLatLong.current = response.results[0].geometry.location.lat + "," + response.results[0].geometry.location.lng
          setDestination(response.results[0].formatted_address)
          return response.results[0].geometry.location.lat + "," + response.results[0].geometry.location.lng
        },
        (error) => {
          throw new Error(error)
        }
      )
    }
    catch (error) {
      throw new Error(error)
    }
  }

  let getViaDestinationAddress = async (index) => {
    try {
      await Geocode.fromAddress(viaDestination[index]).then(
        async (response) => {
          if (response.status !== "OK") {
            throw new Error('No Results found for Via Destination')
          }
          await handleUpdateViaLatLong(response.results[0].geometry.location.lat + "," + response.results[0].geometry.location.lng, index)
          handleUpdateVia(response.results[0].formatted_address, index)
          return
        },
        (error) => {
          throw new Error(error)
        }
      )
    }
    catch (error) {
      throw new Error(error)
    }
  }

  let getRouteInfo = async (e) => {
    try {
      let res = await fetch(process.env.REACT_APP_LAMBDA_URL,
      {
        method: "POST",
        headers: new Headers({
          'x-api-key': process.env.REACT_APP_LAMBDA_API_KEY, 
          'Content-Type': 'application/json',
          'Authorization': auth.user.accessToken,
        }),
        body: JSON.stringify({
          origin: originLatLong.current,
          via: viaDestinationLatLongRef.current.filter(function (viaDest) {return viaDest !== ""}),
          destination: destinationLatLong.current,
          depart_at: departAt,
          timezone: new Date().getTimezoneOffset(),
          rate1_rate: auth.user.settings.rate1_rate,
          rate2_rate: auth.user.settings.rate2_rate,
          rate3_rate: auth.user.settings.rate3_rate,
          rate1_meter_start: auth.user.settings.rate1_meter_start,
          rate2_meter_start: auth.user.settings.rate2_meter_start,
          rate3_meter_start: auth.user.settings.rate3_meter_start,
          rate2_start_time: auth.user.settings.rate2_start_time,
          rate2_end_time: auth.user.settings.rate2_end_time,
          traffic_rate: auth.user.settings.traffic_rate,
          round: auth.user.settings.round,
          holiday_rate: holiday_rate,
          extra_seats: seats,
          extra_seats_multiplier: auth.user.settings.extra_seat_multiplier || 0,
          travel_mode: auth.user.settings.travel_mode || "taxi"
        })
      });
      let resJson = await res.json();
      if (res.status === 200) {

        if (resJson.statusCode === 200) {
          setJson(resJson.summary);
        }
        else if (resJson.statusCode === 400) {
          toast.error('No Route Found', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        }
        else {
          toast.error('Unknown Error', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        }

      }
      else if (res.status === 403) {
        toast.error('Daily Limit Exceeded', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
      }
    } catch (error) {
      console.log(error);
    }
  }

  let handleSubmit = async (e) => {
    e.preventDefault();
    setJson({})
    var success = true;

    try {
      await getOriginAddress();
    } catch (error) {
      success = false;
      toast.error('Invalid Origin Address', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }
    if (!success) { return }

    await Promise.all(viaDestination.map(async (viaDest, index) => { 
        try {
          await getViaDestinationAddress(index);
        } catch (error) {
          if (viaDest !== "") {
            success = false;
            toast.error('Invalid Via Address (' + viaDest + ')', {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });
          }
        }  
      }
    ))
    if (!success) { return }

    try {
      await getDestinationAddress();
    } catch (error) {
      success = false;
      toast.error('Invalid Destination Address', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        }); 
    }
    if (!success) { return }

    try {
      await getRouteInfo();
    } catch (error) {
      toast.error('Error with Route', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }
    
  }

  let handleToggleDetailed = async (e) => {
    detailedPrice.current = !detailedPrice.current
    const newPrice = {detailedPrice: detailedPrice.current}
    const newJSON = {"settings": {...auth.user.settings, ...newPrice}}
    updateUser(auth.user.uid, newJSON);
  }

  function handleUpdateVia(newName, index) {
    const vias  = viaDestination;
    const newVias = [...vias];
    newVias[index] = newName;
    setViaDestination(newVias);
  }

  let handleUpdateViaLatLong = async (newLatLong, index) => {
    const viasLatLong  = viaDestinationLatLong;
    const newViasLatLong = [...viasLatLong];
    newViasLatLong[index] = newLatLong;
    setViaDestinationLatLong(newViasLatLong);
    return
  }

  function handleAddVia() {
    setViaDestination([...viaDestination, ""])
    setViaDestinationLatLong([...viaDestinationLatLong, ""])
  }

  function handleRemoveVia(index) {
    const newVias = [...viaDestination];
    newVias.splice(index, 1);
    setViaDestination(newVias);
    const newViasLatLong = [...viaDestinationLatLong];
    newViasLatLong.splice(index, 1);
    setViaDestinationLatLong(newViasLatLong);
  }

  return (
    <>
      <Meta />
      <meta 
        name='viewport' 
        content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0' 
      />
      <Navbar />
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
        <div className="md:flex md:space-x-8 px-2 py-4 md:px-8 md:py-8">

          <div className="overflow-visible card md:w-1/2 bg-base-300 shadow-xl">
            <div className="card-body">
              <h2 className="card-title pb-8">Journey Picker</h2>
              <input type="text" value={origin} placeholder="Starting Location" className="input input-bordered md:input-md input-sm  w-full" onChange={(e) => setOrigin(e.target.value)} />
              {viaDestination ? viaDestination.map((viaDest, index) => 
              <div key={`div-${index}`}className="flex gap-2">
                <input key={`input-${index}`} type="text" value={viaDest} placeholder="Via" className="input input-bordered md:input-md input-sm w-11/12" onChange={(e) => handleUpdateVia(e.target.value, index)} />
                <button key={`button-${index}`} className="btn btn-ghost btn-circle btn-md" onClick={()=> handleRemoveVia(index)}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" /></svg>
                </button> 
              </div>
              ) : null}
              <div className="flex gap-2">
                <input type="text" value={destination} placeholder="Destination" className="input input-bordered md:input-md input-sm w-11/12" onChange={(e) => setDestination(e.target.value)} />
                <button className="btn btn-circle btn-md" onClick={() => ( viaDestination.length < 150 ? handleAddVia() : null)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" /></svg>
                </button>
              </div>
              <div className="btn-group pt-2">
                <input type="radio" name="selectedWeekday" data-title="Mon" value="1" className="btn px-5" onChange={handleWeekdayChange} checked={weekdayState === 1 ? true : false}/>
                <input type="radio" name="selectedWeekday" data-title="Tue" value="2" className="btn px-5" onChange={handleWeekdayChange} checked={weekdayState === 2 ? true : false}/>
                <input type="radio" name="selectedWeekday" data-title="Wed" value="3" className="btn px-5" onChange={handleWeekdayChange} checked={weekdayState === 3 ? true : false}/>
                <input type="radio" name="selectedWeekday" data-title="Thu" value="4" className="btn px-5" onChange={handleWeekdayChange} checked={weekdayState === 4 ? true : false}/>
                <input type="radio" name="selectedWeekday" data-title="Fri" value="5" className="btn px-5" onChange={handleWeekdayChange} checked={weekdayState === 5 ? true : false}/>
                <input type="radio" name="selectedWeekday" data-title="Sat" value="6" className="btn px-5" onChange={handleWeekdayChange} checked={weekdayState === 6 ? true : false}/>
                <input type="radio" name="selectedWeekday" data-title="Sun" value="0" className="btn px-5" onChange={handleWeekdayChange} checked={weekdayState === 0 ? true : false}/>
              </div>

              <div className="flex pt-2">
                <select className="select select-bordered w-2/16 select-sm md:select-md max-w-xs" onChange={handleHourChange} value={hourState}>
                  {hoursArray.map((i) => {
                    return <option key={"hour-:" + i} value={i}>{hoursLabelArray[i]}</option>
                  })}
                </select>
                <select className="select select-bordered w-2/16 select-sm md:select-md max-w-xs" onChange={handleMinuteChange} value={minuteState}>
                  {minutesArray.map((i, arr) => {
                    return <option key={"min-:" + i} value={i}>{minutesLabelArray[arr]}</option>
                  })}
                </select>
                <button className="btn btn-primary md:btn-md btn-sm" onClick={handleNow}>Now</button>
              </div>

              <div className="justify-start">
                <label className="label justify-start cursor-pointer">
                  <span className="label-text">Holiday Rate?&nbsp;&nbsp;&nbsp;</span>
                  <input 
                    type="checkbox" 
                    checked={holiday_rate} 
                    className ="toggle toggle-primary md:toggle-lg" 
                    onChange={() => setHoliday_rate(!holiday_rate)}
                  />
                </label>
                <label className="label justify-start cursor-pointer">
                  <span className="label-text">Extra Seats?&nbsp;&nbsp;&nbsp;</span>
                  <input 
                    type="checkbox" 
                    checked={seats} 
                    className ="toggle toggle-primary md:toggle-lg" 
                    onChange={() => setSeats(!seats)}
                  />
                </label>
              </div>

              <div className="card-actions pt-0 md:pt-8">
                <button className="btn btn-primary md:btn-md btn-sm" onClick={handleSubmit}>Get Price</button>
              </div>
            </div>
          </div>
          <div className="pt-4 md:pt-0"></div>
          <div className="card md:w-1/2 bg-base-300 shadow-xl">
            <div className="card-body">

            <div className="flex">
              <p className="card-title justify-start">{detailedPrice.current ? "Detailed Price" : "Price"}</p>
              <p className="label justify-end">Details?&nbsp;&nbsp;&nbsp;</p>

              <div className="flex justify-center items-center">
                  <input 
                    type="checkbox" 
                    checked={detailedPrice.current} 
                    className ="toggle toggle-primary" 
                    onChange={() => handleToggleDetailed()}
                  />
                </div>
            </div>

              {detailedPrice.current ? (
                <div className="overflow-x-auto">
                  <table className="table table-compact w-full">
                    <tbody>
                      <tr>
                        <th>Origin</th>
                        <td>{origin}</td>
                      </tr>
                      <tr>
                        <th>Via</th>
                        <td>{json.stops}{json.stops === 1 ? " Stop" : " Stops"}</td>
                      </tr>
                      <tr>
                        <th>Destination</th>
                        <td>{destination}</td>
                      </tr>
                      <tr>
                        <th>Distance</th>
                        <td>{json.lengthInMilesText}</td>
                      </tr>
                      <tr>
                        <th>Duration</th>
                        <td>{json.travelTimeInText}</td>
                      </tr>
                      <tr>
                        <th>Traffic</th>
                        <td>{json.hasOwnProperty('trafficDelayInSeconds') ? (json.trafficDelayInSeconds > 0 ? json.trafficDelayInText : "All Clear" ) : null}</td>
                      </tr>
                      <tr>
                        <th>Start Price</th>
                        <td>{json.debug_start_price? "£" + json.debug_start_price.toFixed(2) : null}</td>
                      </tr>
                      <tr>
                        <th>Rate</th>
                        <td>{json.debug_rate}</td>
                      </tr>
                      <tr>
                        <th>Mileage Cost</th>
                        <td>{json.debug_milage_cost? "£" + json.debug_milage_cost.toFixed(2) : null}</td>
                      </tr>
                      <tr>
                        <th>Traffic Cost</th>
                        <td>{json.hasOwnProperty('debug_traffic_cost') ? (json.debug_traffic_cost > 0 ? "£" + json.debug_traffic_cost.toFixed(2) : "£0" ) : null}</td>
                      </tr>
                      <tr>
                        <th className="text-xl">Price</th>
                        <td className="text-xl">{json.hasOwnProperty('price') ? "£" + json.price.toFixed(2): ""}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ):( 
                <div className="stat place-items-center">
                  <div className="stat-value text-7xl">{json.hasOwnProperty('price') ? "£" + json.price.toFixed(2): ""}</div>
                  <div className="stat-desc pt-8">{json.hasOwnProperty('price') && auth.user.settings.round !== "no" ? "Rounded " + auth.user.settings.round : null}</div>
                </div>
              )}
            </div>
          </div>
        </div>
    </>
  );
}

export default requireAuth(IndexPage);
